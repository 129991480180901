
import Vue from 'vue'
import {
  favicons,
  HeadOptions,
  images,
  metadata,
} from '~/assets/ts/utils/metadata'
import { DefaultThemeColor, SaEvent, SaEventTheme } from '~/models/events'
import { HSLA } from '~/assets/ts/utils/color'
import { customSiteParams } from '~/assets/ts/utils/params'

const DEFAULT_THEME = SaEventTheme.Foundational

export default Vue.extend({
  name: 'Events',
  middleware: ['fetchEvent', 'fetchWebcasts'],
  head(this: any) {
    const options = {
      title: this.event?.title,
      description: this.event?.subtitle,
      favicons: favicons('events', this.themeColor),
      htmlAttrs: {
        class: 'sa-event',
        style: `--theme-color: ${this.themeColor}; --theme-color-highlight: ${this.themeColorHighlight}; --theme-color-dark: ${this.themeColorDark}; background-color: var(--theme-color);`,
      },
    } as HeadOptions
    if (this.eventImage) {
      options.images = images(this.eventImage, true)
    } else {
      options.images = images(require('@/assets/images/ogImage.png'))
    }
    return metadata(this, options)
  },
  computed: {
    color(): HSLA {
      return this.event ? this.event.color : DefaultThemeColor(DEFAULT_THEME)
    },
    themeColor(): string {
      return this.color.css
    },
    themeColorHighlight(): string {
      const base = this.color
      return new HSLA({
        hue: base.hue,
        saturation: base.saturation,
        lightness: base.lightness + 20,
      }).css
    },
    themeColorDark(): string {
      const base = this.color
      return new HSLA({
        hue: base.hue,
        saturation: base.saturation,
        lightness: base.lightness - 18,
      }).css
    },
    eventID(): string {
      return customSiteParams(this).id
    },
    eventImage(): string | undefined {
      return this.event?.metadataImageUrl
    },
    event(): SaEvent | undefined {
      // const eventID = this.$route.params.eventID
      // https://stackoverflow.com/questions/49951504/how-can-i-load-a-component-based-on-the-domain-in-a-nuxt-vue-app
      // https://i18n.nuxtjs.org/different-domains/
      const eventData = this.$store.getters['events/event'](this.eventID)
      if (eventData) {
        return new SaEvent(eventData)
      }
      return undefined
    },
  },
})
