export interface PhotoOptions {
  url: string
  name: string
  description?: string
  link?: string
}

export class Photo implements PhotoOptions {
  url: string
  name: string
  description?: string
  link?: string
  constructor(info: PhotoOptions) {
    this.url = info.url
    this.name = info.name
    this.description = info.description || undefined
    this.link = info.link || undefined
  }

  get isLocalLink(): boolean {
    if (!this.link) return false
    return !this.link.includes('http')
  }
}

// API format example:
// {
//   "albumID": "mi123456789",
//   "albumSequence": 1,
//   "broadcasterID": "misc",
//   "createDate": null,
//   "description": null,
//   "imageHeight": 0,
//   "imageID": 151810,
//   "imageName": "pix-tfc22-bju.jpg",
//   "imageWidth": 0,
//   "legacyURL": null,
//   "shortDescription": null,
//   "url": "https://media-cloud.sermonaudio.com/images/photos/pix-tfc22-bju.jpg"
// },
