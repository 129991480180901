import { Broadcaster } from '~/models/broadcaster'
import { Speaker } from '~/models/speaker'
import { Photo } from '~/models/photos'
import { HSLA, saBlueHsla } from '~/assets/ts/utils/color'
import {
  apiTimestampToJsTimestamp,
  daysBetweenDates,
} from '~/assets/ts/utils/date'
import { Sermon } from '~/models/sermon'

// we currently only have one theme
export enum SaEventTheme {
  Foundational,
}

export function DefaultThemeColor(theme: SaEventTheme): HSLA {
  switch (theme) {
    case SaEventTheme.Foundational:
    default:
      return saBlueHsla
  }
}

export interface SaEventRegistrationResponse {
  count: number
  eventID: string
  ticketID: string
}

export class SaEventAdditionalAttendee {
  firstName: string
  lastName: string
  mealPlan: boolean
  constructor() {
    this.firstName = ''
    this.lastName = ''
    this.mealPlan = false
  }
}

export class SaEventRegistration {
  firstName: string
  lastName: string
  email: string
  street?: string
  city?: string
  state?: string
  zipcode?: string
  phone?: string
  title?: string
  church?: string
  comments?: string
  constructor(info: any) {
    this.firstName = info.firstName
    this.lastName = info.lastName
    this.email = info.email
    this.street = info.street || undefined
    this.city = info.city || undefined
    this.state = info.state || undefined
    this.zipcode = info.zipcode || undefined
    this.phone = info.phone || undefined
    this.title = info.title || undefined
    this.church = info.church || undefined
    this.comments = info.comments || undefined
  }

  get submission(): Record<any, any> {
    const value = {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
    } as Record<any, any>
    if (this.street) value.street = this.street
    if (this.city) value.city = this.city
    if (this.state) value.state = this.state
    if (this.zipcode) value.zipcode = this.zipcode
    if (this.phone) value.phone = this.phone
    if (this.title) value.title = this.title
    if (this.church) value.church = this.church
    if (this.comments) value.comments = this.comments
    return value
  }
}

export class SaEventLocation {
  address: string
  id: string
  lat: number
  lng: number
  streetNumber?: string
  street?: string
  city?: string
  state?: string
  stateShort?: string
  country?: string
  countryShort?: string
  zipcode?: string
  constructor(locationData: any) {
    locationData = JSON.parse(locationData)
    this.address = locationData.formatted_address
    this.lat = locationData.geometry.location.lat
    this.lng = locationData.geometry.location.lng
    this.id = locationData.place_id
    for (let i = 0; i < locationData.address_components.length; i++) {
      const component = locationData.address_components[i]
      const types = component.types
      if (types.includes('street_number')) {
        this.streetNumber = component.long_name
      }
      if (types.includes('route')) {
        this.street = component.short_name
      }
      if (types.includes('locality')) {
        this.city = component.long_name
      }
      if (types.includes('administrative_area_level_1')) {
        this.state = component.long_name
        this.stateShort = component.short_name
      }
      if (types.includes('country')) {
        this.country = component.long_name
        this.countryShort = component.short_name
      }
      if (types.includes('postal_code')) {
        this.zipcode = component.long_name
      }
    }
  }
}

export class SaEventNote {
  id: string
  text?: string
  rank: number
  title: string
  constructor(note: any) {
    this.id = note.noteID
    this.text = note.note || undefined
    this.rank = note.rank
    this.title = note.title
  }
}

export class SaEventSessions {
  id: string
  dateBegin: Date
  dateEnd?: Date
  dateBeginTimestamp: number
  dateEndTimestamp?: number
  text?: string
  subtitle: string
  title: string
  constructor(session: any) {
    this.dateBeginTimestamp = apiTimestampToJsTimestamp(session.beginDate)
    this.dateBegin = new Date(this.dateBeginTimestamp)
    this.dateEndTimestamp = session.endDate
      ? apiTimestampToJsTimestamp(session.endDate)
      : undefined
    this.dateEnd = this.dateEndTimestamp
      ? new Date(this.dateEndTimestamp)
      : undefined
    this.text = session.notes || undefined
    this.id = session.sessionID.toString()
    this.subtitle = session.subtitle || undefined
    this.title = session.title
  }
}

export class SaEventSpeaker {
  name: string
  title: string
  speaker?: Speaker
  rank: number
  constructor(speaker: any) {
    this.name = speaker.displayName ? speaker.displayName : speaker.speakerName
    this.speaker = speaker.speakerAtSermonAudio
      ? new Speaker(speaker.speakerAtSermonAudio)
      : undefined
    this.rank = speaker.rank
    this.title = speaker.speakerTitle
  }
}

export class SaEventTicket {
  id: string
  eventID: string
  count: number
  constructor(registration: SaEventRegistrationResponse) {
    this.id = registration.ticketID
    this.eventID = registration.eventID
    this.count = registration.count
  }
}

export class SaEvent {
  id: string
  allowRegistration: boolean
  attendeeType?: string
  broadcaster: Broadcaster
  category: string
  currentRegistrations: number
  cost: number
  groupCost: number
  maxGroupSize: number
  mealCost?: number
  color: HSLA
  currency: string
  dateBegin: Date
  dateEnd: Date
  dateBeginTimestamp: number
  dateEndTimestamp: number
  description: string
  generalNotes?: string
  location?: SaEventLocation
  locationText: string
  locationUrl: string
  maxRegistrations?: number
  notes?: SaEventNote[]
  registrations?: SaEventTicket[]
  sessions?: SaEventSessions[]
  speakers?: SaEventSpeaker[]
  subtitle?: string
  timeText?: string
  title: string
  timezone: string
  theme: SaEventTheme
  url?: string
  videoSermonID?: string
  heroSermon?: Sermon
  logoImageUrl?: string
  locationImageUrl?: string
  headerImageUrl: string
  hostImageUrl?: string
  metadataImageUrl?: string
  hostUrl?: string
  photos: Photo[]

  constructor(event: Record<string, any>) {
    this.allowRegistration = event.acceptRegistration
    this.attendeeType = event.attendeeProfile || undefined
    this.broadcaster = new Broadcaster(event.broadcaster)
    this.category = event.category
    this.cost = parseFloat(event.cost)
    this.currentRegistrations = event.currentRegistrations || 0
    this.groupCost = event.groupCost ? parseFloat(event.groupCost) : 0
    this.mealCost = event.mealPlanCost
      ? parseFloat(event.mealPlanCost)
      : undefined
    this.maxGroupSize = 20 // this may later become a value passed from the event itself
    this.timezone = event.timezone || 'America/New_York'
    // to test this timezone in other timezones:
    // this.timezone = 'Pacific/Fiji' // UTC+12
    // this.timezone = 'Pacific/Rarotonga' // UTC-10

    this.currency = event.currency || 'USD'
    this.registrations = event.registrations
      ? event.registrations.map((r: any) => new SaEventTicket(r))
      : undefined
    this.dateBeginTimestamp = apiTimestampToJsTimestamp(event.dateBegin)
    this.dateBegin = new Date(this.dateBeginTimestamp)
    this.dateEndTimestamp = event.dateEnd
      ? apiTimestampToJsTimestamp(event.dateEnd)
      : apiTimestampToJsTimestamp(event.dateBegin + 24 * 60 * 60)
    this.dateEnd = new Date(this.dateEndTimestamp)
    this.description = event.description
    this.id = event.eventID
    if (event.eventNotes && event.eventNotes.length) {
      this.notes = event.eventNotes
        .map((n: any) => new SaEventNote(n))
        .sort((a: SaEventNote, b: SaEventNote) => (a.rank < b.rank ? -1 : 1))
    }
    this.generalNotes = event.generalNotes || undefined
    this.locationText = event.locationInfo || undefined
    this.locationUrl = event.locationURL || undefined
    this.maxRegistrations = event.maxRegistrations || undefined
    if (event.sessions && event.sessions.length) {
      this.sessions = event.sessions
        .map((s: any) => new SaEventSessions(s))
        .sort((a: SaEventSessions, b: SaEventSessions) =>
          a.dateBeginTimestamp < b.dateBeginTimestamp ? -1 : 1
        )
    }
    if (event.speakers && event.speakers.length) {
      this.speakers = event.speakers
        .map((s: any) => new SaEventSpeaker(s))
        .sort((a: SaEventSpeaker, b: SaEventSpeaker) =>
          a.rank < b.rank ? -1 : 1
        )
    }
    this.subtitle = event.subtitle || undefined
    this.timeText = event.time || undefined
    this.title = event.title
    this.url = event.url || undefined
    this.videoSermonID = event.videoURL || undefined
    this.heroSermon = event.heroSermon
      ? new Sermon(event.heroSermon)
      : undefined

    if (event.locationJSON) {
      this.location = new SaEventLocation(event.locationJSON)
    }

    if (event.gallery) {
      const p = [] as Photo[]
      const images = event.gallery.images ? event.gallery.images : []
      for (let i = 0; i < images.length; i++) {
        p.push(
          new Photo({
            url: images[i].url,
            name: images[i].shortDescription,
            description: images[i].description,
          })
        )
      }
      this.photos = p
    } else {
      this.photos = []
    }

    // we currently only have one theme, but eventually we'll pass a value in
    this.theme = SaEventTheme.Foundational
    this.color = DefaultThemeColor(this.theme)

    this.hostUrl = event.hostURL || undefined
    this.hostImageUrl = event.hostGraphicURL || undefined
    this.logoImageUrl = event.logoGraphicURL || undefined
    this.locationImageUrl = event.locationGraphicURL || undefined
    this.metadataImageUrl = event.metaGraphicURL || undefined
    this.headerImageUrl =
      event.heroGraphicURL || require('@/assets/images/events/hero.png')
  }

  get registrationClosed(): boolean {
    return !this.allowRegistration && !!this.currentRegistrations
  }

  get soldOut(): boolean {
    if (this.maxRegistrations === undefined) return false
    return this.remainingRegistrations <= 0
  }

  get remainingRegistrations(): number {
    return Math.max(0, (this.maxRegistrations || 0) - this.currentRegistrations)
  }

  get singleDayEvent(): boolean {
    return !this.dateEnd
  }

  get dayCount(): number {
    if (this.singleDayEvent) return 1
    return daysBetweenDates(this.dateBegin, this.dateEnd)
  }

  get address(): string | undefined {
    return this.location && this.location.address
      ? this.location.address
      : undefined
  }

  get shortAddress(): string | undefined {
    return this.location && this.location.city && this.location.stateShort
      ? `${this.location.city}, ${this.location.stateShort}`
      : undefined
  }

  get free(): boolean {
    return !!this.cost
  }

  get groupAvailable(): boolean {
    return this.groupCost >= this.cost
  }

  getMealCost(count: number): number {
    return this.mealCost ? this.mealCost * count : 0
  }

  getSubtotal(group: boolean, freeCoupon: boolean, mealPlans: number): number {
    const base = freeCoupon ? 0 : group ? this.groupCost : this.cost
    return base + this.getMealCost(mealPlans)
  }

  inProgress(): boolean {
    const timestamp = new Date().getTime()
    return (
      timestamp > this.dateBeginTimestamp && timestamp < this.dateEndTimestamp
    )
  }
}
